<template>
  <div>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="fas fa-user"></i><span class="ml-3">SubCodes</span>
      </h3>

      <span class="float-right green-btn">
        <a class="btn btn-success mr-3" @click="create">
          <i class="material-icons">add</i> <span>SubCode</span></a
        >
      </span>
    </div>
    <div>
      <v-row>
      <v-col class="d-flex" cols="12" sm="3">
          <v-text-field
            v-model="search"
            label="Search User"
            counter
            filled
            rounded
            clearable
            dense
            maxlength="50"
          >  <template #label>
                           <i class="fas fa-search"></i>  Search Service 
                          </template> </v-text-field>
        </v-col>
        <v-col class="d-flex mt-4" cols="12" sm="6">
          <v-btn @click="Search"> Search </v-btn></v-col
        ></v-row
      >
    </div>
    <div
      v-if="!isfetching"
      class="container mt-10"
      style="background-color: #ffffff"
    >
      <b-table
        class="table table-bordered b-table"
        show-empty
        select-mode="single"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="0"
        striped
        hover
        selectable
        @row-selected="onRowSelected"
      >
        <template #cell(actions)="row">
          <button @click="Edit(row.item)" class="mr-1">
            <i class="far fa-eye edit_btn"></i>
          </button>
          <button
            v-b-modal.confirmModal
            @click="sendInfo(row.item.id)"
            class="mr-1"
          >
            <i class="fas fa-trash-alt del_btn"></i>
          </button>
        </template>
        <template #cell(vendorCode)="row">
          {{ vendorCode(row.item) }}
        </template>
        <template #cell(rate)="row"> $ {{ row.item.rate }} </template>
        <template #cell(caregiverRate)="row">
          $ {{ row.item.caregiverRate }}
        </template>
      </b-table>
      <div class="row">
        <div class="col-6">
          <b-pagination
           v-if="perPage != '-1'"
            size="md"
            :total-rows="totalItems"
            v-model="currentPage"
            :per-page="perPage"
          ></b-pagination>
        </div>
        <div class="col-6 text-right">
          <span style="margin-right: 10px"><label for="">Show : </label></span>
          <select
            v-model="perPage"
            class="form-select"
            style="border: groove; width: 6%"
            aria-label="Default select example"
            @change="Search()"
          >
            <option selected>50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="-1">All</option>
          </select>

          <span style="margin-left: 10px; margin-right: 20px">
            <label for=""> Entries </label></span
          ><span>TOTAL RECORDS: {{ this.totalItems }}</span>
        </div>  </div>
    </div>
    <div
      v-else
      class="container mt-10"
      style="background-color: #ffffff; text-align: center"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <b-modal size="xl" id="subcode" centered no-close-on-backdrop>
      <template slot="modal-title">SubCode/Service</template>
      <v-container data-app fluid>
        <div v-if="this.id" class="page-title pageheading" style="height: 50px">
          <span class="float-right green-btn">
            <a
              v-if="isEditAble"
              class="btn btn-success mr-3"
              @click="editClientinfo"
            >
              <i class="far fa-eye edit_btn"></i><span>Edit</span>
            </a>
            <a v-else class="btn btn-success mr-3" @click="ok()">
              <i class="far fa-eye edit_btn"></i>
              <span>
                Update
                <span v-if="isSending">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </span>
              </span>
            </a>
          </span>
        </div>
        <v-row align="center">
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.SubCode.$error }"
          >
            <v-text-field
              v-model.trim="$v.SubCode.$model"
              :disabled="isEditAble"
              :rules="[rules.required]"
              label="SubCode/Service"
              maxlength="20"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.SubCode.$error">
              <span v-if="$v.SubCode.$error">SubCode is required</span>
            </div>
          </div>

          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.SubCodeDescription.$error }"
          >
            <v-text-field
              v-model.trim="$v.SubCodeDescription.$model"
              :rules="[rules.required]"
              label="Description"
              :disabled="isEditAble"
              maxlength="100"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.SubCodeDescription.$error">
              <span v-if="$v.SubCodeDescription.$error"
                >SubCodeDescription is required</span
              >
            </div>
          </div>
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.rates.$error }"
          >
            <v-text-field
              v-model.trim="$v.rates.$model"
              :disabled="isEditAble"
              :rules="[rules.required]"
              label="Payer pay rate"
              maxlength="20"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.rates.$error">
              <span v-if="$v.rates.$error">Rates is required</span>
            </div>
          </div>
          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.Caregiverrate.$error }"
          >
            <v-text-field
              v-model.trim="$v.Caregiverrate.$model"
              :rules="[rules.required]"
              label="Caregiver pay rate"
              :disabled="isEditAble"
              maxlength="20"
            ></v-text-field>
            <div class="invalid-feedback" v-if="$v.Caregiverrate.$error">
              <span v-if="$v.Caregiverrate.$error"
                >Caregiverrate is required</span
              >
            </div>
          </div>

          <div
            class="form-group col-md-6 mb-form"
            :class="{ invalid: $v.Vendors.$error }"
          >
            <v-select
              v-model.trim="$v.Vendors.$model"
              :items="vendorList"
              item-text="vendorCode"
              item-value="id"
              :disabled="isEditAble"
              :menu-props="{ maxHeight: '400' }"
              label="Vendor Code"
              persistent-hint
              multiple
            ></v-select>
            <div class="invalid-feedback" v-if="$v.Vendors.$error">
              <span v-if="$v.Vendors.$error">Vendors is required</span>
            </div>
          </div>
        </v-row>
      </v-container>
      <template #modal-footer="{ cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button v-if="id" size="lg" variant="success" @click="ok()">
          Update
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular
          ></span>
        </b-button>
        <b-button v-else size="lg" variant="success" @click="ok()">
          Create
          <span v-if="isSending">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular
          ></span>
        </b-button>
        <b-button size="lg" variant="danger" @click="cancel()">
          Cancel
        </b-button>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
    <b-modal
      centered
      text
      id="confirmModal"
      title="Are you sure you want to Delete?"
      hide-footer
    >
      <div class="text-center">
        <p>Are you sure you want to delete?</p>
        <span class="green-btn">
          <a class="btn btn-success mr-3" @click="remove(subcodeid)"
            ><span>YES I'M SURE</span>
            <span v-if="isRemoving">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular> </span
          ></a>
        </span>
        <span class="green-btn">
          <a class="btn btn-danger mr-3" @click="$bvModal.hide('confirmModal')"
            ><span>Cancel</span></a
          >
        </span>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations: {
    SubCode: {
      required,
      minLength: minLength(1),
    },
    SubCodeDescription: {
      required,
      minLength: minLength(1),
    },
    rates: {
      required,
      minLength: minLength(1),
    },
    Caregiverrate: {
      required,
      minLength: minLength(1),
    },
    Vendors: {
      required,
      minLength: minLength(1),
    },
  },
  data() {
    return {
      isEditAble: true,
      isfetching: true,
      isRemoving: false,
      isSending: false,
      items: [],
      SubCode: "",
      SubCodeDescription: "",
      rates: "",
      Caregiverrate: "",
      Vendors: [],

      subcodeid: "",
      fields: [
        {
          key: "subCode",
          label: "SubCode/Service",
        },
        {
          key: "subCodeDescription",
          label: "Description",
        },
        {
          key: "rate",
          label: "Payer Pay Rate",
        },
        {
          key: "caregiverRate",
          label: "Caregiver Pay Rate",
        },
        {
          key: "vendorCode",
          label: "Vendor Code",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      currentPage: 1,
      perPage: 50,
      totalItems: 10,

      search: "",
      companyName: "",
      phoneNumber: "",
      location: "",
      id: "",
      type: "",
      caseworkers: [],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
      },
    };
  },
  watch: {
    currentPage: {
      handler: function () {
        this.fetchData().catch((error) => {
          console.error(error);
        });
      },
    },
    totalRow: {
      handler: function () {
        this.items = this.subcode;
      },
    },
  },
  mounted() {
    this.fetchData().catch((error) => {
      console.error(error);
    });
    this.$store.dispatch("getVendorList");
  },
  computed: {
    ...mapGetters(["subcode", "totalSubCodeRow", "vendorList"]),
  },
  methods: {
    editClientinfo() {
      this.isEditAble = false;
    },
    resetForm() {
      (this.SubCode = ""),
        (this.SubCodeDescription = ""),
        (this.rates = ""),
        (this.Caregiverrate = ""),
        (this.Vendors = []),
        (this.id = "");
    },
    vendorCode(obj) {
      return obj.subCodeVendors
        .map((e) => {
          return e.vendorCode;
        })
        .toString();
    },
    sendInfo(item) {
      this.subcodeid = item;
    },
    create() {
      this.resetForm();
      this.isEditAble = false;
      this.$bvModal.show("subcode");
    },
    Search() {
      this.fetchData();
    },
    async fetchData() {
      this.isfetching = true;
      this.search = this.search == null ? "" : this.search;
      await this.$store
        .dispatch("getsubcode", {
          pageNo: this.currentPage,
          pageSize: this.perPage,
          filter: this.search,
        })
        .then((response) => {
          if (response.message == "Success") {
            this.items = this.subcode;
            this.totalItems = response.data.totalRow;
            this.isfetching = false;
          }
        })
        .catch((ex) => {
          this.isfetching = false;
          
        });
    },
    onRowSelected(items) {
      this.Edit(items[0]);
    },
    Edit(obj) {
      if (obj != undefined) {
        this.Vendors = [];
        obj.subCodeVendors.forEach((element) => {
          this.Vendors.push(element.vendorID);
        });
        this.id = obj.id;
        this.SubCode = obj.subCode;
        this.SubCodeDescription = obj.subCodeDescription;
        this.rates = obj.rate;
        this.Caregiverrate = obj.caregiverRate;
        this.isEditAble = true;
        this.$bvModal.show("subcode");
      }
    },
    remove(obj) {
      this.isRemoving = true;
      this.$store
        .dispatch("removesubcode", {
          id: obj,
        })
        .then((response) => {
          this.items = this.subcode;
          this.isRemoving = false;
           this.fetchData()
          if (response.message == "Success") {
            Swal.fire({
              title: "",
              text: "The subcode has been Deleted Successfully!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
            });
          }
        })
        .catch((ex) => {
          this.isRemoving = false;
          Swal.fire({
            title: "",
            text: "Error!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
          
        });
      this.$bvModal.hide("confirmModal");
    },
    ok() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSending = true;
        var obj = [];
        this.Vendors.forEach((e) => {
          obj.push({ vendorID: e });
        });
        if (this.id) {
          this.$store
            .dispatch("editsubcode", {
              id: this.id,
              subCode: this.SubCode,
              subCodeDescription: this.SubCodeDescription,
              rate: parseFloat(this.rates),
              caregiverRate: parseFloat(this.Caregiverrate),
              subCodeVendors: obj,
            })
            .then((response) => {
              this.items = this.subcode;
              this.isSending = false;
              this.fetchData();
              Swal.fire({
                title: "",
                text: "The subcode has been Updated Successfully!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              this.$bvModal.hide("subcode");
            })
            .catch((ex) => {
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
              
            });
        } else {
          this.$store
            .dispatch("savesubcode", {
              subCode: this.SubCode,
              subCodeDescription: this.SubCodeDescription,
              rate: parseFloat(this.rates),
              caregiverRate: parseFloat(this.Caregiverrate),
              subCodeVendors: obj,
            })
            .then((response) => {
              this.items = this.subcode;
              this.fetchData();
              if (response.message == "Success") {
                this.isSending = false;
                Swal.fire({
                  title: "",
                  text: "The subcode has been Created Successfully!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
              }
              this.$bvModal.hide("subcode");
            })
            .catch((ex) => {
              
              this.isSending = false;
              Swal.fire({
                title: "",
                text: "Error!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
              });
            });
        } 
      }
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
  },
};
</script>
<style scoped>
.titlename {
  float: left !important;
  padding-left: 19px;
}
.pageheading {
  padding-top: 20px;
  padding-bottom: 50px;
  height: 50px;
  background-color: #ffffff;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 1px !important;
}
.b-table thead {
  background: #1b5d77 !important;
  color: #fff !important;
  font-size: 14px;
  font-family: "Open Sans-SemiBold", Arial, Helvetica, sans-serif !important;
}
.table {
  box-shadow: 0 0 5px #ccc;
}
.row {
  margin: 0px !important;
}
.form-group.invalid .invalid-feedback {
  display: block;
  text-align: initial;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: 80%;
  color: #dc3545;
}

.edit_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.edit_btn:hover {
  color: #FB8C00 !important;
};
.del_btn{
  cursor: pointer !important;
  color: #757575 !important;
}
.del_btn:hover {
  color: #BF360C !important;
}
</style>
